import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"
import { PrivacyContent } from "../components/privacy/PrivacyContent"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="プライバシーポリシー,トナリノ,デザイン,デザイン相談"
      description="トナリノのプライバシーポリシーをご確認いただけます。"
      ogTitle="プライバシーポリシー"
      ogDescription="トナリノのプライバシーポリシーページです"
      ogUrl="https://tonarino.co/privacy"
    />
    <PageTitleBox title="プライバシーポリシー" />
    <section id="privacyPolicyContent">
      <PrivacyContent />
    </section>
  </Layout>
)

export default IndexPage
