import * as React from "react"

export const PrivacyContent = () => {
  return (
    <>
      <div className="container pageBodyBox">
        <section id="description">
          <p>「トナリノ」（以下「運営者」といいます）は、トナリノの利用者（以下「利用者」といいます）の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます）に従い、適切な取扱い及び保護に努めます。</p>

          <p>1 本プライバシーポリシーにおいて、個人情報とは、個人情報保護法の定義に従うものとし、その他の定義については、トナリノ利用規約（以下「利用規約」といいます）に従うものとします。</p>
          <p>2 運営者は、トナリノ（以下「本サービス」といいます）の提供にあたって、以下の個人情報を取得し、利用目的に従って利用いたします。</p>

          <ol>
            <li className="list-unstyled">(1) 本サービス提供に際しての個人情報取得
              <ol>
                <li className="list-unstyled">① 取得個人情報：
                  <ul>
                    <li>
                      利用申請時：氏名、メールアドレス等の情報及び必要に応じた個人のホームページ、SNSアカウント、YouTubeなどの情報
                    </li>
                    <li>
                    アドバイザリーサービスの実施時：利用者から伝達される利用者の状況、課題等に関する一切の情報</li>
                  </ul>
                </li>
                <li className="list-unstyled">② 利用目的： 
                  <ul>
                    <li>本サービスを提供するため</li>
                    <li>本サービスに関するご案内、お問い合わせ等への対応のため</li>
                    <li>本サービスの運営に関する事務連絡を行うため</li>
                    <li>利用規約に違反する行為に対する対応のため</li>
                    <li>その他、上記利用目的に付随する目的のため</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="list-unstyled">
              (2) 本サイトの問合せフォームにおける個人情報取得
              <ol>
                <li className="list-unstyled">① 取得個人情報：氏名、メールアドレス、問い合わせ本文 等</li>
                <li className="list-unstyled">② 利用目的：
                  <ul>
                    <li>問い合わせ内容の検討及び返信のため</li>
                    <li>その他上記利用目的に付随する目的のため</li>
                  </ul>
                </li>
              </ol>
            <li className="list-unstyled">
              (3) 本サイトにおけるCookie及びこれに類する技術を利用した個人情報取得
              <ol>
                <li className="list-unstyled">① 取得個人情報：Cookie等を通じて取得する情報</li>
                <li className="list-unstyled">② 利用目的：運営者がアクセス解析やアフィリエイトプログラム、広告配信サービスを利用する場合、かかるサービス利用のため</li>
                <li className="list-unstyled">③ Cookieについてのご説明：<br />
                  Cookieとは、利用者のコンピュータ内に記録されるものであり、個人を特定するものは一切含まれません。Cookieを無効化したい利用者は、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、運営者のサービスの一部の機能をご利用いただけなくなる場合があります。
                </li>
              </ol>
            </li>
          </li>
          <li className="list-unstyled">
            3 利用者は、運営者がGoogle アナリティクスサービスを利用するにあたり、以下に同意するものとします。
            <ul>
              <li>運営者が利用者のデータ収集のために Cookie を使用していること</li>
            </ul>
          </li>
          <li className="list-unstyled">4 運営者は、個人情報保護法その他の法令により許容される場合を除き、利用者の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
            <ol>
              <li className="list-unstyled">（1） 法令に基づく場合</li>
              <li className="list-unstyled">（2） 人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき</li>
              <li className="list-unstyled">（3） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき</li>
              <li className="list-unstyled">（4） 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            </ol>
          </li>
          <li className="list-unstyled">
            5 運営者は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
          </li>
          <li className="list-unstyled">
            6 運営者は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理を図るものとし、運営者に従業員がいる場合、必要かつ適切な監督を行います。また、運営者は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
          </li>
          <li className="list-unstyled">
            7 運営者は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめ利用者の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
            <ol>
              <li className="list-unstyled">（1） 運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</li>
              <li className="list-unstyled">（2） 合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              <li className="list-unstyled">（3） 個人情報保護法の定めに基づき共同利用する場合</li>
            </ol>
          </li>
          <li className="list-unstyled">8 運営者は、利用者から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、利用者ご本人からのご請求であることを確認の上で、利用者に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします）。但し、個人情報保護法その他の法令により、運営者が開示の義務を負わない場合は、この限りではありません。</li>
          <li className="list-unstyled">9 運営者は、利用者から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、利用者ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を利用者に通知します（訂正等を行わない旨の決定をしたときは、利用者に対しその旨を通知いたします）。但し、個人情報保護法その他の法令により、運営者が訂正等の義務を負わない場合は、この限りではありません。</li>
          <li className="list-unstyled">10  運営者は、利用者から、利用者の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます）を求められた場合において、そのご請求に理由があることが判明した場合には、利用者ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨を利用者に通知します。但し、個人情報保護法その他の法令により、運営者が利用停止等の義務を負わない場合は、この限りではありません。</li>
          <li className="list-unstyled">11  開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、本サイトの問い合わせフォーム等を通じて、運営者までご連絡ください。</li>
          <li className="list-unstyled">12  運営者は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。</li>
        </ol>
        <p class="text-end mb-5">以上</p>
        </section>
      </div>
    </>
  )
}
